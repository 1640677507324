/** FONT INSTALLED **/

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/roboto-light-webfont.woff2") format("woff2"),
    url("../fonts/roboto/roboto-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/roboto-regular-webfont.woff2") format("woff2"),
    url("../fonts/roboto/roboto-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/roboto-medium-webfont.woff2") format("woff2"),
    url("../fonts/roboto/roboto-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/roboto-bold-webfont.woff2") format("woff2"),
    url("../fonts/roboto/roboto-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.simplebar-track.simplebar-vertical {
  width: 6px;
}

.simplebar-scrollbar:before {
  background: #a8a8a8;
  border-radius: 10px;
  left: 0;
  right: 0;
}

body,
html {
  font-family: "Roboto", sans-serif !important;
}

body * {
  font-family: "Roboto", sans-serif !important;
}

html * {
  font-family: "Roboto", sans-serif !important;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input:focus {
  box-shadow: none !important;
}

.visiblity_hidden {
  visibility: hidden;
}

.col {
  min-width: unset !important;
}

/** FONT INSTALLED **/

@keyframes pulse {
  0% {
    box-shadow: 0px 1px 0px 3px #aba81a30, 0px 0px 0px 0px #2179F9;
  }

  30% {
    box-shadow: 0px 1px 0px 3px #aba81a30, 0px 0px 0px 5px #2179F9;
  }

  70% {
    box-shadow: 0px 1px 0px 3px #aba81a30, 0px 0px 0px 10px #2179F9;
  }

  100% {
    box-shadow: 1px 1px 3px 1px #aba81a30, 0px 0px 0px 20px transparent;
  }
}

* {
  outline: none !important;
}

a:hover {
  color: #af2431 !important;
}

a:active {
  color: #af2431 !important;
}

button.btn:hover {
  filter: saturate(0.8);
}

.btn {
  white-space: nowrap;
}

.hidden {
  display: none !important;
}

button.close {
  font-weight: 100;
  font-size: 40px;
}

.text-secondary {
  color: #d24350 !important;
}

.bg-grey {
  background-color: #e5e5e5 !important;
}

.loader-span {
  display: none;
}

.loader-active {
  display: inline-block;
}

.pl-alert .alert-heading {
  font-weight: 400;
}

.pl-alert p {
  font-weight: 400;
}

.virtual-heading {
  font-size: medium;
  font-weight: 500;
  color: #666666;
  margin-bottom: 15%;
}

.login-img {
  width: 110%;
  height: 100vh;
}

.login-button {
  background-color: #d24350;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
}

.missed-box {
  background-color: #5c5c5c;
}

.showMissedWrn {
  background: #fadb40 !important;
  border: 1px solid #5c5c5c !important;
  box-shadow: rgba(92, 92, 92, 0.5) 0px 0px 1px 1px !important;
  padding-top: 0.9rem !important;
  padding-bottom: 0.9rem !important;
}

.t-n-c {
  text-align: left;
  margin-top: 2%;
  color: #666666;
  font-size: 1.1rem;
}

.login-tc {
  color: #d24350 !important;
  line-height: 0.56;
}

.login-privacy {
  color: #d24350 !important;
}

.login-mail {
  color: #666666;
  /* font-size: 15px; */
  text-align: left;
  font-size: 1.1rem;
}

.text-danger-mail {
  color: #d24350 !important;
}

.text-dark-pl {
  color: #d24350;
  font-weight: 500 !important;
}

.no-appointement {
  font-weight: 500 !important;
  color: #d24350;
}

/* .login-image {
  background-image: url("../../assets/images/ic-loginimage.png");
  background-repeat: no-repeat;
  height: auto;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 190%;
} */

.otp-logo {
  width: 30%;
}

.otp-text {
  color: #333333;
  font-size: 20px;
  font-weight: 300;
}

.otp-btn {
  background-color: #d34350;
  font-weight: bold;
  /* width: 35%; */
  font-size: 15px;
}

.Resend-otp {
  color: #d24350;
}

.otp-virtual-name {
  color: #666666;
  margin-bottom: 10px;
}

.vertical-line {
  border-left: 1px solid #666666;
  height: 72px;
  opacity: 0.3;
}

.video-icon:hover {
  color: white !important;
}

.popup-content {
  width: 35% !important;
  border-radius: 7px;
}

.close {
  font-weight: 0 !important;
}

.popup>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 10px;
  top: 10px;
  font-size: 30px;
  color: #666666 !important;
  font-weight: 100 !important;
  z-index: 1;
}

.popup>.close>img {
  width: 20px;
  color: #b2b2b2;
}

.popup>.close>img:hover {
  width: 20px;
  color: black;
}

.tnc-data>.close {
  cursor: pointer;
  /* position: absolute; */
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 10px;
  top: 10px;
  font-size: 34px;
  position: -webkit-sticky;
  position: sticky;
}

.tnc-pop-content {
  width: 82% !important;
  height: 80%;
  margin-top: 100px !important;
  overflow-y: scroll;
  border-radius: 7px;
}

.tnc-data>.container-fluid>.tnc-data {
  margin-left: 62px;
  margin-right: 40px;
}

.tnc-data>.container-fluid>.tnc-title {
  margin-left: 622px !important;
  font-size: 23px;
  font-weight: bold;
}

.popup>.button>.button-in {
  min-width: 100px;
  height: 35px;
  border-radius: 6px;
  /* background-color: #d24350; */
  font-weight: 900;
  /* margin-left: 37%; */
  align-items: center !important;
  font-size: 18px !important;
  line-height: 15px;
}

.title-pl {
  color: #242424;
  font-weight: 500;
  font-size: 17px;
}

.radio {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkround {
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-color: #d24350;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  margin-right: 20px;
}

.radio input:checked~.checkround {
  background-color: #fff;
}

.checkround:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked~.checkround:after {
  display: block;
}

.radio .checkround:after {
  left: 4.8px;
  top: 4.4px;
  width: 11.5px;
  height: 11.5px;
  border-radius: 50%;
  background: #d24350;
}

.cancel-pop {
  width: 35% !important;
  border-radius: 7px;
  padding: 2rem !important;
}

.registered-patient {
  background-color: #f6f6f6;
  margin: -5px;
  padding: 10px 10px 20px;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.patient-datacontainer {
  max-height: 215px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 10px 0 5px;
}

.patient-details p {
  font-weight: 400;
  color: #333333;
}

.patient-datacontainer>.row:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.patient-datacontainer>.row {
  white-space: nowrap;
}

.patient-datacontainer>.row label {
  margin: 0
}

.label1 {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
  white-space: nowrap;
}

#patient_call_widget {
  white-space: nowrap;
}

.video-btn .img-btn {
  background-color: white;
  border-radius: 7px;
  font-weight: 500;
  min-width: 180px;
  height: 36px;
  border: 2px solid #d24350;
  line-height: 0 !important;
  /* padding-top: 3px; */
}

.video-btn .img-btn>img {
  width: 29px;
}

.video-btn .img-btn img:nth-child(2) {
  display: none;
}

.video-btn .img-btn:hover img:nth-child(1),
.video-btn .img-btn:active img:nth-child(1) {
  display: none;
}

.video-btn .img-btn:hover img:nth-child(2),
.video-btn .img-btn:active img:nth-child(2) {
  display: block;
}

/* .video-btn .img-btn:active img, .video-btn .img-btn:hover img {
  filter: brightness(2030%);
  -webkit-filter: brightness(2030%);
  -moz-filter: brightness(2030%);
} */
.video-btn .img-btn:hover,
.video-btn .img-btn:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.audio-btn {
  text-align: right;
  margin: 0;
}

.audio-btn button {
  margin: 0 !important;
}

.audio-btn .img-btn {
  border-color: #333;
  color: #333;
}

.audio-btn .img-btn>img {
  width: 17px;
}

.audio-btn .img-btn:hover,
.audio-btn .img-btn:active {
  color: #fff;
  background-color: #333 !important;
  border-color: #333 !important;
}

.video-conferencing-column {
  height: 100%;
}

.ck-editor {
  height: inherit !important;
  font-weight: 500;
  color: #333;
}

.ck.ck-editor__main {
  height: inherit !important;
}

.ck-content {
  height: calc(100% / 2) !important;
  color: #333;
}

.ck-dropdown__panel-visible {
  max-height: 110px;
  overflow: hidden;
  overflow-y: auto;
}

/* .ck-focused {
  height: 172px !important;
  color: #a2a2a2;
} */

.text-feild {
  /* border: 1px solid #a2a2a2; */
  /* width: 10%; */
  height: 100%;
  padding: 10px;
  /* height: calc(435px / 2); */
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
  /* overflow: hidden; */
  position: relative;
}

.icon-mute-btn {
  position: absolute;
  top: 3px;
  right: 0px;
  border: none;
  background: none;
  border-radius: 100px;
}

.icon-mute-btn img {
  height: 30px;
  border-radius: 100px;
}

.icon-mute-btn.active img {
  animation: 1.5s linear 0s infinite normal pulse;
}

.text-feild1 {
  border: none;
  width: 100%;
  height: calc(100% - 30px);
  color: #333;
}

textarea:focus {
  outline: none;
}

/* .doctor-video {
  border: 2px solid #a2a2a2;
  height: 370px;
} */

.doc-notes-text {
  /* height: 150px;
  border: none;
  width: 530px; */
  color: #333;
  height: calc(100% - 50px);
  font-weight: 400;
  border: none;
  width: 530px;
  overflow: auto;
  margin: 0;
  float: unset !important;
  width: 100%;
  word-break: break-word;
  /* text-transform: capitalize; */
}

.no-notes {
  color: #666 !important;
  font-weight: 400 !important;
}

.doctor-notes {
  /* border: solid 1px #a2a2a2;
  background-color: #f8f7f7;
  /* background-color: ; */
  /* height: calc(400px / 2); */
  height: 100%;
  border: solid 1px #d8d8d8;
  background-color: #f8f7f7 !important;
}

.ck-editor__top {
  margin-top: 31px;
}

.doc-upload {
  text-align: left;
  font-weight: bold;
  margin-right: 6px !important;
  white-space: nowrap;
}

.doc-upload+div {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.doc-upload+div button {
  margin: 4px 4px;
}

.doc-upload+div img {
  height: 28px;
}

.jpg {
  /* width: 30px; */
  height: 28px;
}

.pdf {
  /* width: 30px; */
  height: 28px;
}

.prescription-notes>.complete-btn {
  font-weight: 550;
  border-radius: 7px;
}

.prescription-notes>.pres-upload>.pres-btn {
  font-weight: 550;
  border-radius: 7px;
}

.doc-notes {
  border: none;
  width: 100%;
  height: 80%;
}

/** NEW STRUCTURE FOR DOCOMP PAGE **/

.beautify_wrapper {
  align-items: center;
}

.beautify_wrapper li path {
  stroke: rgb(210, 67, 80);
  stroke-width: 1px;
}

.cf_wrapper {
  height: calc(100vh - 200px);
  /* overflow: hidden; */
}

.cf_wrapper>.row {
  flex: 1;
}

.cf_wrapper>.row:nth-child(2) {
  flex: 20;
  max-height: calc(100% - 130px);
}

.cf_wrapper>.row:nth-child(2)>div {
  max-height: 100%;
}

.cf_wrapper>.row:nth-child(2)>div:nth-child(1) {
  padding-right: 12px;
}

.cf_wrapper>.row:nth-child(3) span {
  font-size: 14px;
}

.notes-section.notes-opd>.row>div:first-child>.row>div {
  padding-left: 15px;
}

.notes-section>.row {
  height: 100%;
  flex-wrap: nowrap;
}

.notes-section>.row>div {
  flex: 1;
  /* max-height: calc(100% / 2); */
  /* max-height: calc((100% / 2) - 6px); */
}

/* .notes-section>.row>div:last-child {
  margin-top: 12px;
} */

.notes-conent {
  height: 100%;
}

.notes-conent>.doctor-notes {
  flex: 2;
  max-height: calc(100% - 102px);
}

.notes-conent>.doctor-notes:nth-child(2) {
  flex: 1;
  max-height: 90px;
  margin-top: 12px;
}

.prescription-notes>.row {
  height: 100%;
}

.notes-section>.row>div>.row {
  height: 100%;
  max-height: 100%;
}

.notes-section>.row>div>.row>div {
  max-height: 100%;
  padding-left: 0;
}

.notes-section>.row>div:first-child>.row>div {
  height: calc((100% / 2) - 6px);
  padding-right: 12px;
}

/* .notes-section>.row>div>.row>div:first-child {
  padding-right: 12px;
} */

div.doctor-presandupdate>div:nth-last-child(n+2),
div.doctor-presandupdate>div:nth-last-child(n+2)~div {
  height: calc((100% / 2) - 6px)
}

div.doctor-presandupdate>div:nth-last-child(n+3),
div.doctor-presandupdate>div:nth-last-child(n+3)~div {
  height: calc((100% / 3) - 8px)
}

.notes-section>.row>div:first-child>.row>div:first-child {
  margin-bottom: 12px;
}

.notes-section>.row>div:last-child>.row>div:not(:last-child) {
  margin-bottom: 12px;
}

.past-prescriptionlist li {
  white-space: nowrap;
}

.past-prescriptionlist li:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}

.past-prescriptionlist li>div {
  flex: 3;
}

.past-prescriptionlist li>div time {
  color: #666;
}

.past-prescriptionlist li>div,
.past-prescriptionlist li>div * {
  font-size: 14px;
}

.past-prescriptionlist li>div:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.past-prescriptionlist li>div:nth-child(2) {
  flex: 2;
  text-align: center;
}

.past-prescriptionlist li>div:nth-child(3) {
  flex: .5 0 15px;
}

.past-prescriptionlist li>div:nth-child(3) button {
  color: #dc3545;
}

/** NEW STRUCTURE FOR DOCOMP PAGE **/

.btn-danger {
  background-color: #d24350 !important;
}

.btn-danger:hover {
  /* background-color: #d24350 !important; */
  border-color: #bdbdbd;
}

.btn-ePrescription {
  background-color: #d24350;
  color: white !important;
  font-size: 1.156vw;
  line-height: 1.156vw;
  font-weight: 500;
}

.btn-ePrescription:hover,
.btn-ePrescription:active,
.btn-ePrescription:focus {
  background-color: #af2431 !important;
  border-color: #af2431 !important;
  color: #fff;
}

/* btns new subheader */

/* alert button + modal style */

.btn-alert {
  background-color: #5c5c5c;
  color: #fff !important;
  font-size: 1.156vw;
  line-height: 1.156vw;
  font-weight: 500;
  border-color: #fff;
}

.btn-alert:hover,
.btn-alert:active,
.btn-alert:focus,
.btn-alert.active {
  background-color: #fadb40 !important;
  border-color: #fadb40 !important;
  color: black !important;
}

.navdrop_itemwrapper {
  max-height: 300px;
  overflow: auto;
}

.alert-modal.modal-header {
  display: flex;
  justify-content: left !important;
  padding: .5rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  background-color: #fadb40;
}

.alert-modal.modal-header::after {
  content: "";
  position: absolute;
  right: 20px;
  top: -17px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 17px 17px 17px;
  border-color: transparent transparent #fadb40 transparent;
  z-index: 9998;
}

.alert-modal-pop.modal-header::after {
  position: absolute;
  right: 20px;
  top: -17px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 17px 17px 17px;
  border-color: transparent transparent #fadb40 transparent;
  z-index: 9998;
}

.alert-modal-pop.modal-header {
  /* display: flex;
    align-items: flex-end; */
  padding: .5rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  text-align: left;
  background-color: #fadb40 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.alert-modal-pop button.close {
  padding-top: 20px;
}

.alert-modal.modal-header .modal-title {
  font-weight: 600;
  line-height: 1.29;
  color: #333333;
}

.modal-body .container .modal-subheader {
  padding: 0.4vw 0;
  display: flex;
  align-items: center;
  background-color: rgb(92, 92, 92) none repeat scroll 0% 0%;
  background-color: rgb(92, 92, 92) !important;
  color: white !important;
  border-radius: 3px;
}

.modal-dialog {
  /* max-width: 900px !important; */
  /* margin: 11.75rem auto; */
}

.radius {
  border-radius: 6px !important;
}

.modal-content .modal-body {
  overflow-y: scroll !important;
  /* max-height: calc(100vh / 1.5); */
}

.alert-modal-pop.modal-header {
  display: flex;
  justify-content: left !important;
}

.modal-dialog.modal-imgdocs {
  max-width: none !important;
}

.modal-dialog {
  max-width: 500px;
  /* margin: 11.75rem auto; */
}

.modal-header .close {
  padding: 1.5rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.btn-allergies.active {
  background-color: #d24350 !important;
  border-color: #d24350 !important;
  color: #fff;
}

.btn-allergies {
  background-color: #5c5c5c;
  color: white !important;
  font-size: 1.156vw;
  line-height: 1.156vw;
  font-weight: 500;
  border-color: #fff;
}

.btn_allergies.overflow {

  padding-top: 21px;
  background-color: #f8f7f7;
}

.btn-allergies:hover,
.btn-allergies:active,
.btn-allergies:focus {
  background-color: #d24350 !important;
  border-color: #d24350 !important;
  color: #fff;
}

.btn-allergies.active {
  background-color: #d24350 !important;
  border-color: #d24350 !important;
  color: #fff;
}

.btn-allergyworklist:hover,
.btn-allergyworklist:focus,
.btn-allergyworklist:active {
  background-color: #fff !important;
  color: #d24350 !important;
  border-color: #d24350;
}

.text-danger {
  color: #d24350 !important;
}

textarea:disabled {
  color: #b3b3b3;
  font-weight: 500;
  cursor: default;
  /* background-color: white !important; */
}

.form-control.is-valid,
.was-validated .form-control.has-error {
  border-color: red;
}

/* .mobilenumbers{
  width: 32.5vw;
} */
.form-control.focus {
  border-color: none !important;
  box-shadow: none !important;
}

.login-imgwrapper {
  height: 100%;
  width: 100%;
  background: url("/static/media/ic-loginimage.bc1504bb.png") no-repeat 20% 0;
  background-size: cover;
}

.modal-dialog.t-c-popup {
  max-width: 1024px;
}

.full-height {
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.full-height>div {
  height: inherit;
}

.loader-1 {
  pointer-events: none;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;
}

.loader-1 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  -webkit-animation: loader-1-2 1.2s linear infinite;
  animation: loader-1-2 1.2s linear infinite;
}

.loader-1 span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  border: 3px solid darkblue;
  border-radius: 50%;
  -webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loader-1-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(220deg);
  }
}

@-webkit-keyframes loader-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
  }

  50% {
    -webkit-transform: rotate(-160deg);
  }

  100% {
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loader-1-3 {
  0% {
    transform: rotate(-140deg);
  }

  50% {
    transform: rotate(-160deg);
  }

  100% {
    transform: rotate(140deg);
  }
}

/** Custom Container **/

.custom-container {
  max-width: 1580px;
}

.container_fluid_custom {
  max-width: 1780px;
}

.powered>p {
  color: #888686;
  font-size: 18px;
  margin-bottom: 10px;
}

.powered>img {
  width: 95px;
}

.powered {
  margin-top: 9rem;
}

/* .login-logo {
  margin-top: 5rem;
} */

.login-section {
  text-align: center;
  max-width: 430px;
  display: inline-block;
}

.login-logo>.logo {
  max-width: 168px;
}

.card-bodycustom {
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 0 10px 6px rgba(115, 115, 115, 0.1);
  /* white-space: nowrap; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.profile {
  margin-top: 40px;
}

.profile-pic {
  width: calc(251px / 2);
  height: calc(251px / 2);
  /* margin-top: 25%;
  margin-left:20%; */
  border: 2px solid #cfcfcf;
  /* background-color: #f2f2f2; */
}

.profile-picwrap p {
  font-size: 18px;
  color: #666666;
}

.profile-sub p {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
}

.profile-sub+div p {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
}

.profile-name {
  color: #d24350;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 0px;
}

.profile-des {
  color: #666666;
}

.prfl-sub-left {
  display: inline-block;
  color: #666666;
  font-size: 18px;
  min-width: 120px;
}

.prfl-sub-right {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}

.profile-details {
  width: 100%;
}

.profile-summary {
  background-color: #f2f2f2;
  border: 1px solid #d1d1d1;
  border-radius: 7px;
  /* padding-top: 13px;
  padding-left: 18px;
  padding-right: 19px;
  padding-bottom: 15px; */
  padding: 16px 20px 20px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #222;
  font-weight: 300;
}

.profile-summary p {
  margin-bottom: 10px;
}

.profile-summary p b {
  font-weight: 500;
}

.profile-edit-btn {
  margin-top: -17px;
}

.profile-edit-btn>a {
  margin-top: -17px;
}

.profile-edit-btn>a {
  color: #d24350 !important;
  font-size: 20px;
  font-weight: 400;
  align-items: flex-end;
}

.profile-edit-btn>a>img {
  width: 19px;
  margin-right: 5px;
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 85px;
  text-overflow: ellipsis;
}

/** Header CSS **/

#nav-healthdropdown {
  color: #333;
  font-size: 1.3vw;
  display: flex;
  align-items: center;
}

#nav-healthdropdown::after {
  margin-left: 0.3em;
}

.vc-name {
  font-size: 24px;
  font-size: 1.3vw;
  font-weight: 400;
  color: #333 !important;
}

.header-doctorimg {
  max-width: 50px;
  box-sizing: content-box;
}

.header-doctorimg>img {
  width: inherit;
}

.header-powered-col {
  border-left: 1px solid #d8d8d8;
}

.header-powered {
  white-space: nowrap;
}

.header-powered>img {
  width: 86px;
  margin-left: 10px;
}

.header-powered>p {
  color: #666666;
  margin-top: 20px;
}

.mhc-logoheader {
  /* height: 90px; */
  height: 5vw;
  width: auto;
}

#nav-healthdropdown+.dropdown-menu {
  top: 96%;
}

.navbar-light .navbar-brand {
  color: #333;
}

/** Heade CSS **/

/** SUB HEADER **/

section.section-subheader {
  padding: 0.4vw 0;
  display: flex;
  align-items: center;
}

.subHead-list>div {
  /* font-size: 22px; */
  font-size: 1.156vw;
  line-height: 1.5vw;
  white-space: nowrap;
}

.subHead-list .list-group-item:first-child {
  text-transform: capitalize;
}

.text-white-sh {
  font-size: 1.156vw;
  color: #fff;
}

.subHead-list>div:first-child {
  border-color: white;
}

.subHead-list>.abc {
  border-left-width: 1px !important;
  border-color: white;
}

.section-subheader span.badge {
  height: 2.1vw;
}

.section-subheader .sub-calendar-icon {
  margin-right: 8px;
  height: 2vw;
  width: auto;
}

.section-subheader .calendar-icon+span {
  font-size: 1.156vw;
}

.slots-color-code {
  position: absolute;
  background-color: #f7f7f7;
  top: 76px;
  left: 0;
  padding-left: 25px;
  width: 103%;
  z-index: 4;
  border-top: 1px solid #71707040;
  padding-bottom: 5px;
  padding-top: 5px;
}

/** Patient list component and card css **/

.tabs-healtvc .nav-link {
  color: #333333;
  background-color: #dcdcdc;
  border: 1px solid #b6b6b6 !important;
  margin-right: -1px !important;
  font-weight: 400;
  font-size: 1.27vw;
}

.tabs-healtvc .nav-link.active {
  color: #d24451;
  background-color: #fff;
  font-weight: 500;
}

.tabs-healtvc .nav-pills {
  border-radius: 10px;
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.1);
}

.tabs-healtvc .nav-pills .nav-item:first-child a {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.tabs-healtvc .nav-pills .nav-item:last-child a {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.tabs-healtvc .nav-link .badge {
  /* font-size: 1.6rem; */
  font-size: 1.3vw;
  color: #333;
}

.healtvc-card-title,
.healtvc-apptime {
  /* font-size: 18px; */
  font-size: .9vw;
  text-transform: capitalize;
  font-weight: 500 !important;
  white-space: nowrap;
}

.healtvc-apptime.pv{
  font-weight: 400 !important;
}

.healtvc-apptime {
  white-space: nowrap;
}

.healtvc-apptime>span:first-child {
  color: #333333;
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.healtvc-apptime>span:first-child img {
  height: auto !important;
  width: 21px;
  margin-right: 5px;
}

.healtvc-apptime>span.late-span {
  color: #d24350;
  /* background-color: #d24350; */
  margin-left: 5px;
  font-size: 14px !important;
}

.card-light-gray.card-subtitle {
  font-weight: 400;
  font-size: 17px;
  color: #666666;
  text-transform: capitalize;
}

.button-variant-red {
  background-color: #d24451 !important;
  border-color: #d24451 !important;
  border-radius: 7px;
}

.button-variant-red-view {
  color: #d24451 !important;
  border-color: #d24451 !important;
  border-radius: 7px;
  background-color: #ffffff !important;
}

.btn-outline-danger-view {
  color: #d24451 !important;
  border-color: #d24451 !important;
  border-radius: 7px;
  background-color: #ffffff !important;
  font-family: "Roboto", sans-serif !important;
}

.btn-outline-danger-view:hover {
  color: #ffffff !important;
  background-color: #d24451 !important;
  font-family: "Roboto", sans-serif !important;
}

.button-variant-red-view:hover {
  color: #ffffff !important;
  background-color: #d24451 !important;
}

.button-variant-cancel {
  border-radius: 7px;
}

.button-variant-view {
  background: transparent;
  color: #d24451;
  border-color: #d24451;
  border-radius: 7px;
}

.button-variant-view:hover {
  background: #d24451;
  border-color: #d24451;
}

.button-variant-view:active {
  background: #d24451 !important;
  border-color: #d24451 !important;
}

.healthvc-tick {
  height: 20px;
  width: 10px;
  display: inline-block;
  border: 2px solid #25a71b;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  margin-right: 12px;
  margin-left: -24px;
}

.pc-doctor-notes {
  font-size: 18px;
  color: #666;
}

.pc-doctor-notes.card-text a {
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
}

.pc-doctor-notes img {
  height: 27px !important;
  width: auto;
}

.pc-doctor-notes.pcd-notes {
  line-height: 22px;
}

/** Patient list component and card css **/

/** MY CALENDAR CSS **/

select#dropdownCountry {
  width: 100%;
  margin-bottom: 1rem;
  height: calc(1.5em + .75rem + 2px);
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fff;
}

/* popup css */

.btns>.can {
  border-radius: 7px;
  border: 1.5px solid #666666;
  width: 192px;
  height: 37px;
  font-weight: bold;
  color: #666666;
}

.btns>.con {
  background-color: #d24350;
  width: 170px;
  height: 37px;
  font-weight: bold;
  border-radius: 7px;
}

.btn-outline-dark:hover {
  background-color: none;
}

button.btn[disabled] {
  cursor: default;
}

button.btn:focus {
  box-shadow: none !important;
}

button.btn.btn-worklist {
  font-size: 1.156vw;
  background: #fff;
  font-weight: 500 !important;
  /* line-height: 18px; */
  padding-top: 6.5px;
  line-height: 1.156vw;
  padding-bottom: 7.5px;
}

button.btn.btn-worklist:hover,
button.btn.btn-worklist:active,
button.btn.btn-worklist:focus {
  background: #fff !important;
  border-color: #af2431;
  color: #af2431;
}

button.btn-workpastpresc.btn-worklist {
  color: #fff;
  font-weight: 400;
  text-shadow: 0 0 0 #fff;
}

button.btn-workpastpresc.btn-worklist:hover {
  background-color: #af2431 !important;
  border-color: #af2431 !important;
  color: #fff;
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 10px;
  top: 10px;
  font-size: 34px;
}

.patient-details {
  color: #5c5c5c;
  font-size: 16px;
  font-weight: 400;
}

.patient-details~.custom-radio label {
  font-size: 16px;
  font-weight: 300;
}

.calendar-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #d24350;
  margin-left: 44%;
}

.title {
  color: #242424;
  font-weight: 500;
}

.cal {
  width: 45px;
  margin-top: 10px;
}

.appointement-details {
  color: #666666;
}

.cancel-btn {
  border-radius: 7px !important;
  border: 1px solid #666666 !important;
  /* width: 192px !important; */
  font-weight: bold !important;
  color: #666666 !important;
  font-size: small !important;
}

.cancel-btn:hover {
  background-color: #f2f2f2;
}

.popup>.btns>.reschedule {
  border-radius: 7px;
  font-weight: bold;
  font-size: small;
}

.patient-name {
  color: #333333;
  font-weight: 500;
  font-size: 17px !important;
  padding-left: 35px;
  text-transform: capitalize;
}

.healthvcst-count {
  position: absolute;
  font-size: 1.4rem;
  color: #d24451;
  right: 50px;
  top: 58%;
  transform: translateY(-50%);
}

.patient-num {
  color: #333333;
  text-align: right;
}

.tabs-healthvccl {
  border-left: 1px solid #ccc;
}

.tabs-healthvccl .accordion .card-header {
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.tabs-healthvccl .tab-content {
  height: 100%;
}

.tabs-healthvccl .tab-content .tab-pane {
  height: 100%;
}

.tabs-healthvccl .tab-content .tab-pane .accordion {
  height: 100%;
}

.tabs-healthvccl .tab-content .tab-pane .card {
  max-height: calc(100% - 60px);
  border-radius: 0;
  border-left: 0;
}

.tabs-healthvccl .tab-content .tab-pane .scroll_loader {
  max-height: 100%;
}

.tabs-healthvccl .tab-content .tab-pane .accordion_collapse_scroll {
  max-height: 300px;
}

.tabs-healthvccl .nav-link.active {
  font-weight: 400;
}

i.healthvc-droparr {
  position: absolute;
  height: 15px;
  width: 15px;
  border: 2px solid #979797;
  border-top: 0;
  border-left: 0;
  right: 20px;
  top: 50%;
  transform: translateY(-80%) rotate(45deg);
}

.cb-stickyheader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  width: calc(100% + 30px);
  z-index: 1;
  left: 0;
  right: 0;
  padding: 8px;
}

.slots-btn>div {
  width: 25%;
  padding: 5px;
  display: inline-block;
}

.slots-btn>div button {
  width: 100%;
  white-space: nowrap;
}

.text-completed,
.text-arrived {
  color: rgba(0, 169, 80, 0.8);
  /* border-color: rgb(18, 109, 203) !important; */
}

.text-missed,
.text-canceled {
  /* color: rgba(18, 109, 203, 0.8); */
  color: rgb(250, 102, 102);
  /* border-color: rgb(8, 184, 16) !important; */
}

.text-confirmed {
  color: rgba(242, 152, 10, 0.8);
  /* border-color: rgb(250, 102, 102) !important; */
}

.text-warning {
  color: #E86100 !important;
}

.button-available {
  color: rgb(18, 109, 203);
  border-color: rgb(18, 109, 203) !important;
}

.button-booked {
  color: rgb(8, 184, 16);
  border-color: rgb(8, 184, 16) !important;
}

.button-blocked {
  color: rgb(250, 102, 102);
  border-color: rgb(250, 102, 102) !important;
  pointer-events: none !important;
}

.button-lapsed {
  color: rgb(102, 102, 102);
  border-color: rgb(102, 102, 102) !important;
  pointer-events: none !important;
}

.button-available:hover {
  background: rgb(18, 109, 203);
}

.button-booked:hover {
  background: rgb(8, 184, 16);
}

.button-blocked:hover {
  background: rgb(250, 102, 102);
}

.button-lapsed:hover {
  background: rgb(102, 102, 102);
}

/** My Calendar plugin CSS **/

.my-calendar * {
  box-sizing: border-box !important;
}

.my-calendar .fc {
  background: #f9f9f9;
  padding-top: 15px;
}

.my-calendar .fc-unthemed .fc-disabled-day {
  background: initial;
}

.my-calendar .fc-content-skeleton {
  pointer-events: none;
}

.my-calendar .fc-highlight:before {
  content: "";
  height: 70px;
  width: 60px;
  border-radius: 7px;
  background: #888282;
  display: block;
}

.my-calendar .fc-highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
}

.my-calendar .fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px;
  padding: 0 55px;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button {
  background: 0 0;
  border: none;
  box-shadow: none;
  outline: 0;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button .fc-icon-left-single-arrow {
  width: 50px;
  height: 40px;
  display: block;
  margin: 0 0 0 20px;
  padding: 0;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button .fc-icon-left-single-arrow:after {
  content: "";
  width: 18px;
  height: 18px;
  border: solid 0.075rem #999;
  display: inline-block;
  transform: rotate(-45deg);
  top: 7px;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button .fc-icon-left-single-arrow:hover:after {
  border-left-color: #333;
  border-top-color: #333;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button .fc-icon-right-single-arrow {
  width: 50px;
  height: 40px;
  display: block;
  margin: 0 20px 0 0;
  padding: 0;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button .fc-icon-right-single-arrow:after {
  content: "";
  width: 18px;
  height: 18px;
  border: solid 0.075rem #999;
  display: inline-block;
  transform: rotate(-45deg);
  top: 7px;
  border-left-color: transparent;
  border-top-color: transparent;
}

.my-calendar .fc-toolbar.fc-header-toolbar .fc-button .fc-icon-right-single-arrow:hover:after {
  border-right-color: #333;
  border-bottom-color: #333;
}

.my-calendar .fc-toolbar.fc-header-toolbar h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  color: #333;
}

.my-calendar .fc-widget-header,
.my-calendar table tbody td,
.my-calendar table thead,
.my-calendar table thead th {
  border: none;
  font-weight: 300;
  text-align: center;
}

.my-calendar .fc-ltr .fc-view .fc-day-top.fc-today .fc-day-number {
  position: relative;
  font-weight: 600;
  min-width: 60px;
  padding-left: 0;
  padding-right: 0;
}

/* .my-calendar .fc-ltr .fc-view .fc-day-top.fc-today .fc-day-number:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: 0 0;
  position: absolute;
  left: 0;
  top: 85%;
  z-index: 999
} */

.my-calendar .fc-ltr .fc-view .fc-day-top .fc-day-number {
  float: unset;
  text-align: center;
  display: block;
}

.my-calendar .fc-event {
  display: inline-block;
  background: 0 0;
  border: none;
  border-top: solid 1px #5caf80;
  border-radius: 0;
  padding: 5px 10px;
  cursor: pointer;
}

/* .my-calendar .fc-event .fc-title {
  color: #d24350;
  font-weight: 500;
  font-size: 16px
} */

.my-calendar .fc-day-number {
  cursor: pointer;
}

.my-calendar .fc-today {
  border: none !important;
  background: 0 0 !important;
}

.my-calendar .fc-today .fc-day-number {
  background: #d24350 !important;
  color: #fff !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.my-calendar .fc-widget-header {
  margin-bottom: 20px;
}

.my-calendar .fc-view .fc-body .fc-row {
  min-height: 60px;
}

.my-calendar .fc-body table thead td span {
  display: inline-block !important;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 18px;
  min-width: 36px;
}

.my-calendar .fc-body table thead td span.booked {
  border-radius: 8px 8px 0 0;
  font-weight: 400;
}

.my-calendar .fc-body table thead td span.active {
  background: #ccc;
  color: #d24350;
}

.my-calendar .fc-body table tbody td a {
  display: inline-block !important;
  padding: 5px 10px;
  border-radius: 0 0 8px 8px;
  margin: -1px 0 0 !important;
  position: relative;
  /* min-width: 46px; */
}

.my-calendar .fc-body table tbody td a.fc-event {
  border-top: none !important;
}

/* .my-calendar .fc-body table tbody td .today a.fc-event {
  background: #d24350;
} */

.my-calendar .fc-body table tbody td a.fc-event:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 100%;
}

.my-calendar .fc-body table tbody td a:after {
  content: "";
  display: block;
  width: 60%;
  height: 1px;
  left: 20%;
  top: 0;
  position: absolute;
  background: #ccc;
}

.my-calendar .fc-body table tbody td a.active {
  background: #ccc;
}

.my-calendar .fc-body table tbody td a.active:after {
  background: #869eb6;
}

.my-calendar .fc-body table tbody td.fc-event-container {
  margin-top: -1px;
}

.my-calendar .fc-body table tbody td .fc-event .fc-title {
  color: #d24350;
  font-size: 18px;
  font-weight: 400;
}

.my-calendar .fc-body table tbody td .today.fc-event {
  background: #d24350;
  padding-left: 0;
  padding-right: 0;
  min-width: 60px;
}

.my-calendar .fc-body table tbody td .today.fc-event .fc-title {
  /* background: #d24350; */
  color: #fff;
  font-weight: bold;
}

.my-calendar .fc-body table tbody td .fc-event .fc-time {
  display: none;
}

.my-calendar .fc-body table tbody td .fc-event:after {
  background: #d24350;
}

.my-calendar .fc-body table tbody td .today.fc-event:after {
  background: #fff;
}

.my-calendar .fc-body table tbody td .fc-event:before {
  height: 0 !important;
}

.my-calendar .fc-toolbar .fc-left *,
.fc-toolbar .fc-right * {
  color: grey;
}

.my-calendar .selected {
  background: lightgrey;
  font-weight: 500 !important;
  min-width: 60px !important;
  position: relative;
}

.my-calendar .selected:after {
  content: "";
  height: 35px;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: lightgray;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.scroll_loader {
  /* max-height: 300px;
  min-height: 300px; */
  overflow-y: auto;
}

.scroll_loader .card-body .card-title {
  text-transform: capitalize;
}

/** MY Calendar CSS **/

/** loader Animation **/

.spinner {
  margin: 0;
  display: inline-block;
  width: auto;
  text-align: center;
}

.spinner>div {
  width: 5px;
  height: 5px;
  background-color: #fff;
  margin: 0 1px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.black>div {
  background: #333;
}

.spinner.red>div {
  background: #d24350;
  height: 20px;
  width: 20px;
  margin: 0 3px;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/** loader Animation **/

#tabs-healthvccont-tabpane-all>div {
  margin-bottom: 30px;
}

.disabled {
  filter: grayscale(1);
  pointer-events: none;
}

.cursor-default {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.modal-dialog.modal-imgdocs {
  max-width: 100%;
  height: 100vh;
  margin: 0;
}

.modal-imgdocs button.close {
  color: white;
  opacity: 1;
}

.modal-dialog.modal-imgdocs .object-wrapper {
  height: auto;
  width: 50%;
  margin: 0 auto;
  display: block;
  z-index: 1;
  position: relative;
}

.modal-dialog.modal-imgdocs .object-wrapper.ow-fullheight {
  min-height: 100vh;
  display: flex;
  align-items: center
}

.modal-dialog.modal-imgdocs object {
  /* width: 100%;
  min-height: 100vh; */
  /* width: auto;
  max-width: 100%;
  max-height: 100vh;
  height: 100vh;
  margin: 0 auto;
  display: block; */
  width: auto;
  max-width: 100%;
  max-height: 100vh;
  margin: 0 auto;
  display: block;
}

.modal-dialog.modal-imgdocs.modal-docs .object-wrapper {
  width: 60%;
  height: 100vh;
}

.modal-dialog.modal-imgdocs.modal-docs object {
  width: 100%;
  height: 100vh;
}

/* .modal-dialog.modal-imgdocs.modal-docs .object-wrapper,
.modal-dialog.modal-imgdocs.modal-docs object {
  height: 100vh;
} */

.img-arrows {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
}

.img-arrows [class^="ir"] {
  width: 60px;
  padding: 10px;
  background: rgba(56, 54, 54, 0.18);
  cursor: pointer;
}

.img-rotate {
  width: 50%;
  margin: 0 auto;
  text-align: right;
  position: relative;
  z-index: 2;
}

.img-rotate [class^="ir"] {
  position: absolute;
  right: 0;
  height: 50px;
  padding: 10px;
  width: 40px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
}

.img-rotate .ir-left {
  right: 40px;
}

.img-rotate [class^="ir"] img {
  width: 100%;
}

.modal-dialog.modal-imgdocs.modal-docs .img-rotate {
  display: none;
}

.modal-dialog.modal-imgdocs .modal-content {
  background-color: transparent;
}

.modal-header {
  display: flex;
  align-items: flex-end;
  justify-content: center !important;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.modal-imgdocs .modal-header {
  position: fixed;
  width: auto;
  border: 0;
  right: 20%;
  top: 0;
  z-index: 3;
}

.xmlns:active {
  color: #d24350;
  fill: rgba(0, 0, 0, 0.02);
  display: block;
  height: 36px;
  width: 36px;
  transition: color 0.5s ease-in-out 0s, fill 0.5s ease-in-out 0s;
}

#svg .style {
  color: red;
  fill: white;
  display: block;
  height: 36px;
  width: 36px;
  transition: color 0.5s ease-in-out 0s, fill 0.5s ease-in-out 0s;
}

.xmlns:active {
  color: #d24350;
  fill: white(0, 0, 0, 0.02);
  display: block;
  height: 36px;
  width: 36px;
  transition: color 0.5s ease-in-out 0s, fill 0.5s ease-in-out 0s;
}

.modal-imgdocs.modal-docs .modal-header {
  right: 13%;
}

button.target>span {
  display: inline !important;
  margin-left: 2px;
}

.edit-feilds.emrfrm {
  padding: 30px;
  margin-top: 15px;
}

.emrfrm .form-group {
  position: relative;
}

.emrfrm .form-control::placeholder {
  color: transparent;
}

.emrfrm .form-control::-moz-placeholder {
  color: transparent;
}

.emrfrm .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none;
}

.emrfrm .form-group label {
  position: absolute;
}

.emrfrm .form-group label {
  pointer-events: none;
  position: absolute;
  margin: 0;
  top: 7px;
  left: 11px;
  font-size: 16px;
  text-shadow: none;
  font-weight: 400;
  color: #666;
  transition: all 0.1s ease-in-out;
}

.emrfrm .form-group {
  position: relative;
}

.emrfrm .form-group.hasdata label {
  transform: translateY(-101%);
  font-size: 13px;
  color: #d24350;
  font-weight: 400;
  background: transparent;
  padding: 0 3px;
  line-height: 13px;
  /* background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(50%, #fff),
    color-stop(50%, #fff),
    color-stop(100%, #fff)
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%,
    #fff 50%,
    #fff 100%
  ); */
}

.emrfrm .form-group.hasdata label::before {
  content: "";
  height: 2px;
  position: absolute;
  background: #fff;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: -1;
}

.emrfrm .form-group.hasdata input[disabled]+label::before {
  content: "";
  height: 2px;
  position: absolute;
  background: #e9ecef;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: -1;
}

/* .emrfrm .form-group.hasdata input[disabled] + label {
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(50%, #e9ecef),
    color-stop(50%, #e9ecef),
    color-stop(100%, #e9ecef)
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%,
    #e9ecef 50%,
    #e9ecef 100%
  );
} */


/** PAST VISIT PAGE **/

.card-bodycustom.card-pastVisit.card-body button.btn {
  margin: 3px 3px;
  border-radius: 7px;
}

.card-bodycustom.card-pastVisit.card-body>.row>div {
  flex: 1;
  align-items: center;
  display: flex;
}

.card-bodycustom.card-pastVisit.card-body>.row>div:first-child {
  flex: 2;
}

.card-bodycustom.card-pastVisit.card-body>.row>div:nth-child(2) {
  flex: 3;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.card-bodycustom.card-pastVisit.card-body>.row>div:nth-child(2) .card-subtitle {
  font-size: 14px;
}

.card-bodycustom.card-pastVisit .d-flex.row {
  align-items: stretch;
}

.card-bodycustom.card-pastVisit.card-body>.row>div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-bodycustom.card-pastVisit.card-body>.row>div:last-child button {
  white-space: nowrap;
}

.text-left-pv {
  font-size: 1.156vw;
  font-weight: 500;
}

/** PAST VISIT PAGE **/

.cal-selected {
  background: red;
}